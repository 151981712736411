import * as React from "react";
import "./style.css";
import Logo from "../images/assets/images/logo.svg";
import HeroImg from "../images/assets/images/hero1.svg";
import HeroMob from "../images/assets/images/mob.svg";
import Arrow from "../images/assets/images/arrow.svg";
import FooterImg from "../images/assets/images/footer-logo.svg";
import { Helmet } from "react-helmet";
import Contact from "../components/Contact";


const service = [
  {
    title: "DevOps Engineer"
  },
  {
    title: "Web Developer"
  },
  {
    title: "App Developement"
  },
  {
    title: "Native/Cross Platform App Development"
  },
  {
    title: "Serverless Architecture"
  },
  {
    title: "UI/UX Design"
  },
  {
    title: "AWS Lambda"
  },
  {
    title: "Education and other Services"
  },

]
const Home = () => {
  return (
    <>
      <React.Fragment>
        <Helmet>
          <title>DeveloperSpot</title>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="../images/assets/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="../images/assets/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="../images/assets/favicon-16x16.png"
          />
          <link
            rel="mask-icon"
            href="../images/assets/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
        </Helmet>
      </React.Fragment>

      <nav id="navbar">
        <img className="logo" src={Logo} alt="DeveloperSpot" />
        <input type="checkbox" id="check" />
        <label htmlFor="check" className="checkbtn">
          <i className="fas fa-bars"></i>
        </label>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Service</li>
          <li>Contact</li>
        </ul>
      </nav>
      <div className="container">
        {/* <!-- Hero Section --> */}
        <div className="hero-section">
          <div className="hero-title">
            <div className="title-text">
              <h1>
                Transform your software <span>development</span> with solid
                skills Developers.
              </h1>
            </div>
            <div className="title-description">
              <h3>
                DeveloperSpot is consulting company. We are offering Software
                Engineer services and development.
              </h3>
            </div>
            <div className="btn">
              <button className="header-btn">Get in touch</button>
            </div>
          </div>
          <div className="hero-img">
            <img className="desk-img" src={HeroImg} alt="hero-image" />
            <img className="mob-img" src={HeroMob} alt="hero-image-mobile"/>
          </div>
        </div>
        {/* <!-- About Section --> */}
        <div className="about-section">
          <div className="about-text">
            <h3>
              DeveloperSpot is consulting company. <br />
              We are offering Software Engineer <br />
              services and development.
            </h3>
          </div>
        </div>
        {/* <!-- Service Section --> */}
        <div className="service-section">

          {service.map((item, index) => {
            return (
              <div className="card" key={index}>
                <div className="arrow">
              <img src={Arrow}  alt="Arrow"/>
            </div>
                <div className="services">
                  <h2>{item.title}</h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Contact Component */}

        {/* <Contact /> */}
      {/* <!-- Footer Section --> */}
      <footer>
        <div className="company-details">
          <div className="title-section">
            <img className="footer-img" src={FooterImg} alt="footer-img" />
          </div>
          <div className="description">
            <h3>
              DeveloperSpot is consulting company. We are offering <br />
              Software services and development.
            </h3>
          </div>
          <button className="footer-btn">Contact Us</button>
          <h3 className="hiring">We are Hiring</h3>
        </div>
      </footer>
      <div className="footer-copyrights">
        <div className="copyright-text">Copyright © 2022 DeveloperSpot</div>
      </div>
    </>
  );
};

export default Home;
